// General & utils
body {
  margin: 0;
  background-color: @dark-color;
}

// Utils
.center {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

// ToDo. We should override antd styles with craco-antd plugin
.App {
  body & {
    color: white;
  }

  ::-webkit-input-placeholder {
    color: @ligthdark-color;
  }

  ::-moz-placeholder {
    color: @ligthdark-color;
  }

  :-ms-input-placeholder {
    color: @ligthdark-color;
  }

  :-moz-placeholder {
    color: @ligthdark-color;
  }

  button:hover {
    opacity: 0.8;
  }

  .ant-btn {
    border-radius: 8px;
    font-weight: bold;
  }

  .ant-btn-primary {
    background-color: @primary-color;
    border-color: @primary-color;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: white;
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
    border: 0;
  }
}
