// Contract page
body.path-contract {
  background-color: @light-color;

  .return-button {
    padding-left: 0;
    font-size: 18px;
    color: @primary-color;
  }
}

.contract-top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract-column {
  .anticon-setting {
    color: @primary-color;
  }

  @media (max-width: 720px) {
    .contract-top-controls {
      display: none;
    }
  }
}

.contract-variables {
  .contract-top-controls {
    display: none;

    @media (max-width: 720px) {
      display: block;
    }
  }
}

.contract-container {
  padding-bottom: 50px;

  .logo {
    margin-bottom: 50px;

    img {
      max-width: 200px;
    }
  }
}

.method-selection {
  // modal close button
  .ant-modal-close {
    &:hover {
      color: @primary-color;
    }
  }

  .ant-modal-content {
    background-color: @almost-white;
    border-radius: 5px;

    .ant-modal-header {
      background-color: @almost-white;
      border-color: @primary-color;
      border-radius: 5px 5px 0 0;

      .ant-modal-title {
        color : @primary-color;
      }
    }

    .ant-modal-body { 
      color : @primary-color;

      .method-selection-button {
        border-color: @primary-color;
        color: @primary-color;
        background-color: transparent;
        padding: 5px 20px;
        border-radius: 8px;
        font-weight: 500;
        &:hover {
          background-color: @medium-color;
        }
      }
    }

    // Customizing default antD checkbox
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @primary-color;
      border-color: @primary-color;
    }
  
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: @primary-color !important;
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-checkbox-group-item {
    width: 45%;
  }
}

.contract-component {
  margin: auto;
  width: 1220px;
  max-width: 95%;

  @media (max-width: 720px) {
    .contract-component-row {
      flex-direction: column-reverse;

      .ant-col {
        margin-bottom: 25px;
      }
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    color: @dark-color;
  }

  .address-component {
    a {
      color: @dark-color !important;
    }
  }

  .contract-methods-display,
  .contract-variables-display {
    background-color: @almost-white;
  }

  .contract-variable {
    margin-bottom: 5px;
  }

  .contract-variable-name {
    font-weight: bold;
    color: @primary-color;
    font-size: 16px;

    svg {
      color: @primary-color;
    }
  }

  .contract-variable-value {
    span {
      word-break: break-all;
      margin-right: 5px;
    }

    svg {
      color: @primary-color;
    }
  }

  .contract-variables-display {
    .address-display {
      svg {
        color: @primary-color;
      }
    }
  }

  .contract-result-action {
    input {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .contract-method-name {
    border-bottom: 1px solid @primary-color;
    color: @primary-color;
    font-size: 16px;
  }

  .contract-method-inputs {
    margin: 10px 0;

    .contract-method-input {
      margin-top: 4px;

      .ant-input-suffix {
        margin: 0 4px;
      }

      .ant-input-affix-wrapper-lg {
        font-size: 14px;
      }

      .ant-input-group-addon {
        border: 0;
        background: transparent;
      }

      & > span {
        background-color: @light-color;
        font-size: 14px;
        border: 0 !important;
      }
    }

    .ant-input-affix-wrapper-disabled {
      padding-right: 0;
      padding-left: 0;
    }

    .helper-buttons-contract-inputs {
      display: flex;
      height: 100%;
      align-items: center;
      gap: 5px;
    }

    .helper-button-contract-input {
      display: flex;
      align-items: center;
    }

    .ant-input-prefix {
      margin: 0;

      & > canvas {
        margin-right: 6px;
      }
    }
  }

  input[type="text"] {
    background-color: @light-color;
    color: @primary-color;
    font-size: 14px;
  }

  .contract-actions-result {
    margin-top: 6px;
  }

  .contract-result-output {
    margin-top: -28px;

    .ant-collapse-item {
      border: 0;
    }

    .ant-collapse-borderless {
      background-color: @almost-white;
    }

    .ant-collapse-header {
      display: inline-block;
      background: #d4f5f3;
      padding: 5px 9px;
      margin-bottom: 6px;
      border-radius: 6px;

      .ant-collapse-arrow {
        margin-right: 6px;
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .contract-actions-wrapper {
    text-align: right;
  }

  .contract-actions {
    display: inline-block;
    z-index: 2;
    position: relative;
  }

  .contract-action-button {
    background-color: @light-color;
    color: @primary-color;
    border: 1px solid @primary-color;
    font-size: 14px;

    &[disabled] {
      background-color: inherit;
      border-color: inherit;
      color: inherit;
    }

    .ant-btn-loading-icon {
      margin-right: 5px;
    }
  }

  .contract-json-output {

    &.output-tx,
    pre {
      word-break: break-all;
      white-space: break-spaces;
      padding: 10px;
      margin: 0;
      background: #D4F5F3;
      color: @primary-color;
    }
  }

  .contract-navigation li {
    color: @primary-color;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.contract-navigation {
  ul {
    list-style: none;
    padding-left: 15px;
  }

  .contract-navigation-content {
    position: sticky;
    padding-top: 50px;
    top: 0;
  }

  span {
    cursor: pointer;
  }
}

.navigation-marker {
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
  height: 100%;
  z-index: -1;

  path {
    transition: all 0.2s ease;
  }
}

.contract-loading-spinner {
  margin-bottom: 50px;
}

.contract-load-error {
  width: 95%;
  max-width: 500px;
  margin: 50px auto;

  p, h3 {
    color: @dark-color;
  }

  h3 {
    margin-bottom: 25px;
    font-weight: bold;
  }

  a {
    color: @primary-color;
  }
}


// JSON Output
.output-tx,
.contract-component .contract-json-output .__json-pretty__ {
  line-height: 1.3;
  overflow: auto;
  color: #056D6D;
}

.__json-pretty__ .__json-key__ {
  color: #056D6D;
}

.__json-pretty__ .__json-value__ {
  color: #551D98;
}

.__json-pretty__ .__json-string__ {
  color: #4A9FED;
}

.__json-pretty__ .__json-boolean__ {
  color: #551D98;
}

.__json-pretty-error__ {
  line-height: 1.3;
  color: black;
  background: @almost-white;
  overflow: auto;
}
