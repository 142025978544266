// Header & Footer

.site-header {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;

  .path-index & {
    justify-content: end;
  }
}

.header-logo {
  max-width: 175px;
  display: none;

  .path-contract & {
    display: block;
  }

  .logo-small {
    display: none;
    max-width: 50px;

    @media (max-width: 720px) {
      display: block;
    }
  }

  @media (max-width: 720px) {
    .logo-small {
      display: block;
    }

    .logo-big {
      display: none;
    }
  }

  .path-contract {
    display: block;
  }
}

.account-info {
  .account-action-button {
    border-color: @light-color;
    color: @light-color;
    background-color: transparent;
    padding: 5px 20px;

    .path-contract & {
      background-color: @light-color;
      color: @primary-color;
      border: 1px solid @primary-color;
    }
  }

  .anticon-copy svg {
    fill: @light-color;

    .path-contract & {
      fill: @primary-color;
    }
  }

  .path-contract & {
    color: @primary-color;
  }

  .address-display a {
    color: white;

    .path-contract & {
      color: @primary-color;
    }
  }

  .address-component {
    @media (max-width: 720px) {
      font-size: 17px !important;
    }
  }
}

// Eth Info faucet section
.eth-info-faucet {
  @media (max-width: 720px) {
    display: none;
  }

  button, .ant-input-affix-wrapper, input {
    background-color: @medium-color;
  }

  .ant-btn-circle {
    background-color: @primary-color;
    border-color: @primary-color;

    svg {
      fill: white;
    }
  }
}

.site-footer {
  padding: 10px 20px;

  .footer-items {
    display: flex;
    justify-content: center;
    gap: 10px;

    .anticon {
      color: @ligthdark-color;
    }

    a {
      color: @ligthdark-color;

      &:hover {
        text-decoration: underline;
      }
    }

    .path-contract & {
      p {
        color: black;
      }

      .anticon {
        color: @primary-color;
      }

      a {
        color: @primary-color;
      }
    }
  }
}

@media (max-width: 720px) {
  .account-info {
    .address-balance {
      display: none;

      & + span {
        display: none;
      }
    }
  }
}
