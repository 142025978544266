body {
  margin: 0;
  background-color: #2D0A49;
}
.center {
  text-align: center;
}
img {
  max-width: 100%;
  height: auto;
}
body .App {
  color: white;
}
.App ::-webkit-input-placeholder {
  color: #AE9EFF;
}
.App ::-moz-placeholder {
  color: #AE9EFF;
}
.App :-ms-input-placeholder {
  color: #AE9EFF;
}
.App :-moz-placeholder {
  color: #AE9EFF;
}
.App button:hover {
  opacity: 0.8;
}
.App .ant-btn {
  border-radius: 8px;
  font-weight: bold;
}
.App .ant-btn-primary {
  background-color: #551D98;
  border-color: #551D98;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6,
.App p {
  color: white;
}
.App .ant-input-affix-wrapper {
  background-color: transparent;
  border: 0;
}
.index-container {
  width: 500px;
  max-width: 90%;
  margin: 0 auto 75px;
}
.index-container .logo {
  text-align: center;
  margin: 30px 0;
}
@media (max-width: 720px) {
  .index-container .logo {
    margin-bottom: 15px;
  }
}
.index-container .logo img {
  width: 325px;
}
@media (max-width: 720px) {
  .index-container .logo img {
    width: 200px;
  }
}
.index-container .lead-text {
  margin-bottom: 50px;
  text-align: center;
}
@media (max-width: 720px) {
  .index-container .lead-text {
    margin-bottom: 35px;
  }
}
.index-container .lead-text p {
  color: #EFEAFF;
  font-size: 19px;
  font-weight: bold;
}
@media (max-width: 720px) {
  .index-container .lead-text p {
    font-size: 16px;
  }
}
.index-container .network-selector {
  margin-bottom: 25px;
}
.index-container .network-selector .ant-select-selection-item {
  font-size: 18px;
}
.index-container .abi-ninja-options {
  background-color: #F9F7FF;
  border-radius: 25px;
}
.index-container .abi-ninja-options .ant-collapse-content {
  border: 0;
  color: #551D98;
  background-color: #F9F7FF;
}
.index-container .abi-ninja-options .ant-collapse-content > .ant-collapse-content-box {
  padding: 5px 26px 26px 26px;
}
.index-container .abi-ninja-options .ant-collapse-item {
  border-bottom: 1px solid #551D98;
}
.index-container .abi-ninja-options .ant-collapse-item:last-child {
  border: 0;
}
.index-container .abi-ninja-options .ant-collapse-header svg {
  transform: rotate(90deg) !important;
}
.index-container .abi-ninja-options .ant-collapse-item-active .ant-collapse-header svg {
  transform: rotate(270deg) !important;
}
.index-container .abi-ninja-options .ant-collapse-header {
  color: #551D98;
  font-size: 22px;
  font-weight: bold;
  position: relative;
  padding: 20px 26px;
}
@media (max-width: 720px) {
  .index-container .abi-ninja-options .ant-collapse-header {
    font-size: 18px;
    padding: 14px 22px;
  }
}
.index-container .abi-ninja-options .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  right: 26px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
.index-container .abi-ninja-options .ant-collapse-header svg {
  width: 1.8em;
  height: 1.8em;
}
@media (max-width: 720px) {
  .index-container .abi-ninja-options .ant-collapse-header svg {
    width: 1.2em;
    height: 1.2em;
  }
}
.index-container .abi-ninja-options .form-item {
  margin-bottom: 20px;
}
.index-container .abi-ninja-options .input-address .ant-input-group-addon {
  background-color: #AE9EFF;
}
.index-container .options-actions {
  text-align: right;
}
.index-container input[type="text"],
.index-container textarea,
.index-container .ant-input-affix-wrapper {
  background-color: #EFEAFF;
}
.index-container .quick-access {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
@media (max-width: 720px) {
  .index-container .quick-access {
    display: block;
    margin-left: 15px;
  }
}
.index-container .quick-access h3 {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}
.index-container .quick-access ul {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
}
@media (max-width: 720px) {
  .index-container .quick-access ul {
    padding: 0;
    margin-top: 6px;
  }
}
.index-container .quick-access ul li {
  cursor: pointer;
  color: #EFEAFF;
  text-decoration: underline;
}
body.path-contract {
  background-color: #EFEAFF;
}
body.path-contract .return-button {
  padding-left: 0;
  font-size: 18px;
  color: #551D98;
}
.contract-top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contract-column .anticon-setting {
  color: #551D98;
}
@media (max-width: 720px) {
  .contract-column .contract-top-controls {
    display: none;
  }
}
.contract-variables .contract-top-controls {
  display: none;
}
@media (max-width: 720px) {
  .contract-variables .contract-top-controls {
    display: block;
  }
}
.contract-container {
  padding-bottom: 50px;
}
.contract-container .logo {
  margin-bottom: 50px;
}
.contract-container .logo img {
  max-width: 200px;
}
.method-selection .ant-modal-close:hover {
  color: #551D98;
}
.method-selection .ant-modal-content {
  background-color: #F9F7FF;
  border-radius: 5px;
}
.method-selection .ant-modal-content .ant-modal-header {
  background-color: #F9F7FF;
  border-color: #551D98;
  border-radius: 5px 5px 0 0;
}
.method-selection .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #551D98;
}
.method-selection .ant-modal-content .ant-modal-body {
  color: #551D98;
}
.method-selection .ant-modal-content .ant-modal-body .method-selection-button {
  border-color: #551D98;
  color: #551D98;
  background-color: transparent;
  padding: 5px 20px;
  border-radius: 8px;
  font-weight: 500;
}
.method-selection .ant-modal-content .ant-modal-body .method-selection-button:hover {
  background-color: #D5C7FF;
}
.method-selection .ant-modal-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #551D98;
  border-color: #551D98;
}
.method-selection .ant-modal-content .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.method-selection .ant-modal-content .ant-checkbox:hover .ant-checkbox-inner,
.method-selection .ant-modal-content .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #551D98 !important;
}
.method-selection .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.method-selection .ant-checkbox-group-item {
  width: 45%;
}
.contract-component {
  margin: auto;
  width: 1220px;
  max-width: 95%;
}
@media (max-width: 720px) {
  .contract-component .contract-component-row {
    flex-direction: column-reverse;
  }
  .contract-component .contract-component-row .ant-col {
    margin-bottom: 25px;
  }
}
.contract-component h1,
.contract-component h2,
.contract-component h3,
.contract-component h4,
.contract-component h5,
.contract-component h6,
.contract-component p {
  color: #2D0A49;
}
.contract-component .address-component a {
  color: #2D0A49 !important;
}
.contract-component .contract-methods-display,
.contract-component .contract-variables-display {
  background-color: #F9F7FF;
}
.contract-component .contract-variable {
  margin-bottom: 5px;
}
.contract-component .contract-variable-name {
  font-weight: bold;
  color: #551D98;
  font-size: 16px;
}
.contract-component .contract-variable-name svg {
  color: #551D98;
}
.contract-component .contract-variable-value span {
  word-break: break-all;
  margin-right: 5px;
}
.contract-component .contract-variable-value svg {
  color: #551D98;
}
.contract-component .contract-variables-display .address-display svg {
  color: #551D98;
}
.contract-component .contract-result-action input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contract-component .contract-method-name {
  border-bottom: 1px solid #551D98;
  color: #551D98;
  font-size: 16px;
}
.contract-component .contract-method-inputs {
  margin: 10px 0;
}
.contract-component .contract-method-inputs .contract-method-input {
  margin-top: 4px;
}
.contract-component .contract-method-inputs .contract-method-input .ant-input-suffix {
  margin: 0 4px;
}
.contract-component .contract-method-inputs .contract-method-input .ant-input-affix-wrapper-lg {
  font-size: 14px;
}
.contract-component .contract-method-inputs .contract-method-input .ant-input-group-addon {
  border: 0;
  background: transparent;
}
.contract-component .contract-method-inputs .contract-method-input > span {
  background-color: #EFEAFF;
  font-size: 14px;
  border: 0 !important;
}
.contract-component .contract-method-inputs .ant-input-affix-wrapper-disabled {
  padding-right: 0;
  padding-left: 0;
}
.contract-component .contract-method-inputs .helper-buttons-contract-inputs {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 5px;
}
.contract-component .contract-method-inputs .helper-button-contract-input {
  display: flex;
  align-items: center;
}
.contract-component .contract-method-inputs .ant-input-prefix {
  margin: 0;
}
.contract-component .contract-method-inputs .ant-input-prefix > canvas {
  margin-right: 6px;
}
.contract-component input[type="text"] {
  background-color: #EFEAFF;
  color: #551D98;
  font-size: 14px;
}
.contract-component .contract-actions-result {
  margin-top: 6px;
}
.contract-component .contract-result-output {
  margin-top: -28px;
}
.contract-component .contract-result-output .ant-collapse-item {
  border: 0;
}
.contract-component .contract-result-output .ant-collapse-borderless {
  background-color: #F9F7FF;
}
.contract-component .contract-result-output .ant-collapse-header {
  display: inline-block;
  background: #d4f5f3;
  padding: 5px 9px;
  margin-bottom: 6px;
  border-radius: 6px;
}
.contract-component .contract-result-output .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px;
}
.contract-component .contract-result-output .ant-collapse-content-box {
  padding: 0 !important;
}
.contract-component .contract-actions-wrapper {
  text-align: right;
}
.contract-component .contract-actions {
  display: inline-block;
  z-index: 2;
  position: relative;
}
.contract-component .contract-action-button {
  background-color: #EFEAFF;
  color: #551D98;
  border: 1px solid #551D98;
  font-size: 14px;
}
.contract-component .contract-action-button[disabled] {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.contract-component .contract-action-button .ant-btn-loading-icon {
  margin-right: 5px;
}
.contract-component .contract-json-output.output-tx,
.contract-component .contract-json-output pre {
  word-break: break-all;
  white-space: break-spaces;
  padding: 10px;
  margin: 0;
  background: #D4F5F3;
  color: #551D98;
}
.contract-component .contract-navigation li {
  color: #551D98;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contract-component .contract-navigation li span:hover {
  opacity: 0.8;
}
.contract-navigation ul {
  list-style: none;
  padding-left: 15px;
}
.contract-navigation .contract-navigation-content {
  position: sticky;
  padding-top: 50px;
  top: 0;
}
.contract-navigation span {
  cursor: pointer;
}
.navigation-marker {
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.navigation-marker path {
  transition: all 0.2s ease;
}
.contract-loading-spinner {
  margin-bottom: 50px;
}
.contract-load-error {
  width: 95%;
  max-width: 500px;
  margin: 50px auto;
}
.contract-load-error p,
.contract-load-error h3 {
  color: #2D0A49;
}
.contract-load-error h3 {
  margin-bottom: 25px;
  font-weight: bold;
}
.contract-load-error a {
  color: #551D98;
}
.output-tx,
.contract-component .contract-json-output .__json-pretty__ {
  line-height: 1.3;
  overflow: auto;
  color: #056D6D;
}
.__json-pretty__ .__json-key__ {
  color: #056D6D;
}
.__json-pretty__ .__json-value__ {
  color: #551D98;
}
.__json-pretty__ .__json-string__ {
  color: #4A9FED;
}
.__json-pretty__ .__json-boolean__ {
  color: #551D98;
}
.__json-pretty-error__ {
  line-height: 1.3;
  color: black;
  background: #F9F7FF;
  overflow: auto;
}
.site-header {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
}
.path-index .site-header {
  justify-content: end;
}
.header-logo {
  max-width: 175px;
  display: none;
}
.path-contract .header-logo {
  display: block;
}
.header-logo .logo-small {
  display: none;
  max-width: 50px;
}
@media (max-width: 720px) {
  .header-logo .logo-small {
    display: block;
  }
}
@media (max-width: 720px) {
  .header-logo .logo-small {
    display: block;
  }
  .header-logo .logo-big {
    display: none;
  }
}
.header-logo .path-contract {
  display: block;
}
.account-info .account-action-button {
  border-color: #EFEAFF;
  color: #EFEAFF;
  background-color: transparent;
  padding: 5px 20px;
}
.path-contract .account-info .account-action-button {
  background-color: #EFEAFF;
  color: #551D98;
  border: 1px solid #551D98;
}
.account-info .anticon-copy svg {
  fill: #EFEAFF;
}
.path-contract .account-info .anticon-copy svg {
  fill: #551D98;
}
.path-contract .account-info {
  color: #551D98;
}
.account-info .address-display a {
  color: white;
}
.path-contract .account-info .address-display a {
  color: #551D98;
}
@media (max-width: 720px) {
  .account-info .address-component {
    font-size: 17px !important;
  }
}
@media (max-width: 720px) {
  .eth-info-faucet {
    display: none;
  }
}
.eth-info-faucet button,
.eth-info-faucet .ant-input-affix-wrapper,
.eth-info-faucet input {
  background-color: #D5C7FF;
}
.eth-info-faucet .ant-btn-circle {
  background-color: #551D98;
  border-color: #551D98;
}
.eth-info-faucet .ant-btn-circle svg {
  fill: white;
}
.site-footer {
  padding: 10px 20px;
}
.site-footer .footer-items {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.site-footer .footer-items .anticon {
  color: #AE9EFF;
}
.site-footer .footer-items a {
  color: #AE9EFF;
}
.site-footer .footer-items a:hover {
  text-decoration: underline;
}
.path-contract .site-footer .footer-items p {
  color: black;
}
.path-contract .site-footer .footer-items .anticon {
  color: #551D98;
}
.path-contract .site-footer .footer-items a {
  color: #551D98;
}
@media (max-width: 720px) {
  .account-info .address-balance {
    display: none;
  }
  .account-info .address-balance + span {
    display: none;
  }
}
